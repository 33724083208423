import React from 'react';

class TextArea extends React.Component{

    constructor(props){
        super(props);  
          this.state = { 
            complete: false

        }
        this.handleBlur = this.handleBlur.bind(this);
      }

      handleBlur(event) {
        if(event.target.value){
            this.setState({
                complete: true
              })
        } else{
            this.setState({
                complete: false
              })
        }
      }

    render(){
        return(
            <div className="TextArea">
                <label htmlFor={this.props.name}>
        
                    <textarea 
                    className={this.state.complete ? "input Complete" : "input"}
                    type={this.props.type}
                    placeholder={this.props.placeholder} 
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    required={this.props.required}
                    onBlur={this.handleBlur}
                    />
                    <span className="InnerLabel">{this.props.label}</span>
                </label>
            </div>
        
        
        );
    }
   
}
export default TextArea;


