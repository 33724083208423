import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactUs from "../components/ContactUs/ContactUs"
import PageTitle from "../components/PageTitle/PageTitle"

const ContactPage = props => {

    const MainCopy = "To contact the Australasian Diving Safety Foundation please use the contact form below and we will get back to you as soon as we can.  Alternatively you can contact info@adsf.org.au.";
    const contact_us_success = "Thank you for contacting us.  We will reply as soon as possible."

    return (
        <Layout>
            <SEO
                title="Contact Us"
            />
            <div className="ContentPage">
                <div className="MainPageSection">
                    <PageTitle PageName="Contact Us" />
                    <div className="ContactPageText">
                        {MainCopy}
                    </div>
                    <ContactUs contact_us_success={contact_us_success}/>
                </div>
            </div>
        </Layout>
    )
}


export default ContactPage

