import React, { Component } from 'react';
import { TextField, Button, FormWrapper, TextArea } from '../common-components'
import SimpleReactValidator from 'simple-react-validator';

  class ContactUs extends Component{
    
      constructor(props){
        super(props);  
          this.validator = new SimpleReactValidator();
          this.state = { 
            email: null,
            name: null,
            phone_number: null,
            message: null,
            loadingDisabled : false,
            finished: false,
            error:false
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
      }
      handleInputChange(event) {
        const target = event.target;
        const name = target.name; 

        this.setState({
          [name]: target.value
        });
      }

    async submitForm(event) {
      event.preventDefault();
        if (this.validator.allValid()) {
          this.setState({loadingDisabled: true});

            var data ={
              "email":this.state.email,
              "phone_number": this.state.phone_number,
              "name": this.state.name,
              "message": this.state.message
            }
            const settings = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            try {
                const fetchResponse = await fetch(`${process.env.GATSBY_EMAIL_ENDPOINT}`, settings);
                const data = await fetchResponse.json();
                this.setState({
                  loading: true,
                });
                if(data.Error){
                  this.setState({
                    error: true
                  });
                }else{
                  this.setState({
                    finished: true
                  });
                }
            } catch (e) {
              this.setState({
                error: true
              });
                return;
            }    

        }else {
          this.validator.showMessages();
          // rerender to show messages for the first time
           this.forceUpdate();
        }
      
      }

    render(){
       return(
         this.state.finished ? 
         <div className="ContactForm">
           <div className="ContactFormSuccess">
             {this.props.contact_us_success}
           </div>
         </div>
         : 
          <div className="ContactForm">
          <FormWrapper>
          <TextField
              type="email"
              name="email"
              label="Email"
              placeholder=""
              onChange={this.handleInputChange}
              required="required"
            />
            {this.validator.message('email', this.state.email, 'required|email')}

          <TextField
              type="text"
              name="name"
              label="Name"
              placeholder=""
              onChange={this.handleInputChange}
              required="required"
            />
            {this.validator.message('name', this.state.name, 'required')}

            <TextField
              type="number"
              name="phone_number"
              label="Phone Number"
              placeholder=""
              onChange={this.handleInputChange}
              required="required"
            />
            {this.validator.message('phone_number', this.state.phone_number, 'required')}


            <TextArea
              type="text"
              name="message"
              label="Message"
              placeholder=""
              onChange={this.handleInputChange}
              required="required"
            />
            {this.validator.message('message', this.state.message, 'required')}
       

            <Button loadingDisabled={this.state.loadingDisabled} type="submit" content="Submit" purpose="Primary" onClick={this.submitForm.bind(this)} />
                        
            </FormWrapper>
        </div>
        
        
        )
    }

  }

export default ContactUs;



