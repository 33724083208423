import React from 'react';

const FormWrapper = ({children}) => (
    <div className="FormWrapper">
        <form onSubmit={e => { e.preventDefault(); }}>
            {children}
        </form>
    </div>
)
   

  export default FormWrapper;