import React from 'react';




class Checkbox extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            checked:false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event){
        this.setState({ checked: event.target.checked }); 
    }

    render(){
    return(
        <div className="Checkbox">   

            <label htmlFor={this.props.name}>{this.props.label}
                <input onChange={this.handleChange} type="checkbox"
                id={this.props.name}
                name={this.props.name} value="Yes"  /> 
                <span class={this.state.checked ? "checkmark checked" : "checkmark"}></span>
            </label>
        </div>
        )
    }
}

export default Checkbox;


