import React from 'react';

const Button = (props) =>{
    var disabled = false;
    if(props.disabled || props.loadingDisabled){
        disabled = true;
    }
    return(
        <button
                onClick={props.onClick}
                disabled={disabled}
                type={props.type}
                className={"btn " + props.purpose}
            >
            {props.loadingDisabled ? "Please wait" : props.content}
            </button>
        )
}
    
    

export default Button;